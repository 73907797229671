@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #4F46E5;
  --secondary-color: #818CF8;
  --text-color: #1F2937;
  --background-color: #FFFFFF;
  --light-gray: #F3F4F6;
  --border-color: #E5E7EB;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  line-height: 1.5;
}

.kululo-prelaunch {
  max-width: 100%;
  overflow-x: hidden;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  padding: 1rem 1rem;
  transition: all 0.3s ease;
  z-index: 1000;
}

.header.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  
  align-items: center;
  max-width: 1350px;
  margin: 0 auto;
}

.logo img {
  height: 30px;
}

.main-nav ul {
  display: flex;
  list-style-type: none;
}

.main-nav ul li {
  margin-left: 2rem;
}

.main-nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

.main-nav ul li a:hover {
  color: var(--primary-color);
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

/* my-new-app/src/CTASection.css */

.cta-section h2 {
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff; /* Darker text for better readability */
}

.cta-section form {
  display: flex;
  flex-direction: column;
}

.cta-section input,
.cta-section textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.cta-section textarea {
  resize: vertical; /* Allow vertical resizing */
  min-height: 100px; /* Minimum height for the text area */
}

.cta-section button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cta-section button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.cta-button:hover {
  background-color: #4338CA;
  transform: translateY(-2px);
}

.menu-toggle {
  display: none;
}

main {
  padding-top: 80px;
}

.hero {
  display: flex;
  align-items: center;
  padding: 6rem 2rem;
  background-color: var(--light-gray);
}

.hero-content {
  flex: 1;
  padding-right: 2rem;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #4B5563;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.social-proof {
  text-align: center;
  padding: 4rem 2rem;
  background-color: var(--background-color);
}

.social-proof p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #4B5563;
}

.logo-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.adopter-group {
  font-weight: 600;
  color: #6B7280;
  transition: all 0.3s ease;
}

.adopter-group:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.video-section {
  padding: 6rem 2rem;
  background-color: var(--light-gray);
}

.video-container {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.video-container video {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.play-button:hover {
  background: rgba(255, 255, 255, 1);
  transform: translate(-50%, -50%) scale(1.1);
}

.features-showcase {
  padding: 6rem 2rem;
  background-color: var(--background-color);
}

.features-showcase h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.features-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.features-nav button {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.features-nav button.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.feature-content {
  max-width: 1000px;
  margin: 0 auto;
}

.feature-item {
  text-align: center;
}

.feature-item h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.feature-item p {
  font-size: 1.1rem;
  color: #4B5563;
  margin-bottom: 2rem;
}

.feature-item img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.testimonial {
  background-color: var(--light-gray);
  padding: 6rem 2rem;
  text-align: center;
}

.testimonial-content {
  max-width: 800px;
  margin: 0 auto;
}

blockquote {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 2rem;
  color: #1F2937;
}

.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem;
}

.author-name {
  font-weight: 600;
  color: #1F2937;
}

.author-title {
  color: #4B5563;
}

.key-benefits {
  padding: 6rem 2rem;
  background-color: var(--background-color);
}

.key-benefits h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.benefit-card {
  background-color: var(--light-gray);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.benefit-card img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.benefit-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1F2937;
}

.benefit-card p {
  color: #4B5563;
}

.cta-section {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 6rem 2rem;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.signup-form {
  display: flex;
  justify-content: center;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.signup-form input[type="email"] {
  flex-grow: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.signup-form .cta-button {
  background-color: white;
  color: var(--primary-color);
}

.signup-form .cta-button:hover {
  background-color: #F3F4F6;
}

.faq {
  padding: 6rem 2rem;
  background-color: var(--light-gray);
}

.faq h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-item {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 2rem;
}

.faq-item h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.faq-item p {
  color: #4B5563;
}

footer {
  background-color: var(--background-color);
  padding: 4rem 2rem 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  height: 32px;
  margin-bottom: 1rem;
}

.footer-links {
  display: flex;
  gap: 4rem;
}

.footer-column h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #1F2937;
}

.footer-column ul {
  list-style-type: none;
}

.footer-column ul li {
  margin-bottom: 0.5rem;
}

.footer-column ul li a {
  text-decoration: none;
  color: #4B5563;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--primary-color);
}

.footer-bottom {
  max-width: 1200px;
  margin: 2rem auto 0;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-social a {
  margin-left: 1rem;
  color: #4B5563;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: var(--primary-color);
}

@media (max-width: 1024px) {
  .hero, .feature-content {
    flex-direction: column;
  }

  .hero-content, .feature-text {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .hero h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .header-content {
    flex-wrap: wrap;
  }

  .main-nav {
    order: 3;
    width: 100%;
    display: none;
  }

  .main-nav.open {
    display: block;
  }

  .main-nav ul {
    flex-direction: column;
  }

  .main-nav ul li {
    margin: 1rem 0;
  }

  .auth-buttons {
    order: 2;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--text-color);
    margin: 5px 0;
    transition: all 0.3s ease;
  }

  .menu-toggle.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-links {
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer-social {
    margin-top: 1rem;
  }

  .signup-form {
    flex-direction: column;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero, .features-showcase, .testimonial, .key-benefits, .cta-section, .faq {
  animation: fadeIn 1s ease-out;
}

.success-message {
  text-align: center;
  padding: 2rem;
  background-color: #e0ffe0; /* Light green background */
  border: 1px solid #4caf50; /* Green border */
  border-radius: 8px;
  margin: 1rem 0;
}

.referral-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.referral-inputs input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.referral-inputs input:focus {
  border-color: #4caf50; /* Change border color on focus */
}

.cta-button {
  background-color: #4caf50; /* Green button */
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45a049; /* Darker green on hover */
}